import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  // ------------- Menu ------------- //
  // ------------- Setting ------------- //
  {
    // path: "/dashboard",
    path: "/",
    // name: "dashboard",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/dashboards/blog"),
  },
  // {
  //   path: "/",
  //   name: "default",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/setting/field"),
  // },
  {
    path: "/setting/field",
    name: "setting-field",
    meta: { authRequired: true },
    component: () => import("./views/setting/field"),
  },
  {
    path: "/setting/category",
    name: "setting-category",
    meta: { authRequired: true },
    component: () => import("./views/setting/category"),
  },
  {
    path: "/setting/tag",
    name: "setting-tag",
    meta: { authRequired: true },
    component: () => import("./views/setting/tag"),
  },
  // ------------- Blog ------------- //
  {
    path: "/blog/list",
    name: "blog-list",
    meta: { authRequired: true },
    component: () => import("./views/blog/list"),
  },
  {
    path: "/blog/manage",
    name: "blog-manage",
    meta: { authRequired: true },
    component: () => import("./views/blog/manage"),
  },
  {
    path: "/blog/list-edit",
    name: "blog-list-edit",
    meta: { authRequired: true },
    component: () => import("./views/blog/list-edit"),
  },
  {
    path: "/blog/manage-edit",
    name: "blog-manage-edit",
    meta: { authRequired: true },
    component: () => import("./views/blog/manage-edit"),
  },
  {
    path: "/blog/highlight",
    name: "blog-highlight",
    meta: { authRequired: true },
    component: () => import("./views/blog/highlight"),
  },
  {
    path: "/blog/interested",
    name: "blog-interested",
    meta: { authRequired: true },
    component: () => import("./views/blog/interested"),
  },
  // ------------- User ------------- //
  {
    path: "/user",
    name: "user",
    meta: { authRequired: true },
    component: () => import("./views/user/list"),
  },
  // ------------- Report ------------- //
  {
    path: "/report",
    name: "report",
    meta: { authRequired: true },
    component: () => import("./views/report/index"),
  },
  // ------------- Activity ------------- //
  {
    path: "/activity",
    name: "activity",
    meta: { authRequired: true },
    component: () => import("./views/activity/index"),
  },
  {
    path: "/activity/detail",
    name: "activity-detail",
    meta: { authRequired: true },
    component: () => import("./views/activity/detail"),
  },
];
