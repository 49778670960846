import axios from "axios";
import constant from "../config/constant";

const axiosInstance = axios.create({
  // Set your default configuration options here
  baseURL: constant.webBaseURL,
  timeout: 5000,
  withCredentials: true,
});

axiosInstance.defaults.timeout = 10000;

axiosInstance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Clear the authentication token and redirect to the login page
      localStorage.removeItem("userInfo");
      localStorage.removeItem("access_token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
